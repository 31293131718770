import { Box } from '@material-ui/core';
import React from 'react';
import { PAIDY_HOST } from 'src/constants';
import ImgPaidyLogoColor from 'src/images/paidy-logo-color.png';

export default function HeaderOnlyPaidyLogo() {
  return (
    <header>
      <a href={`${PAIDY_HOST}`} target="_blank" rel="noopener noreferrer">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={64}
        >
          <img
            alt="paidy-logo"
            width={115}
            height={46}
            src={ImgPaidyLogoColor}
          />
        </Box>
      </a>
    </header>
  );
}
