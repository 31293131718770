import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { PAIDY_HOST } from 'src/constants';
import styles from './ConditionsV1.module.scss';

const DATA = [
  {
    title: 'キャンペーン概要',
    text: (
      <>
        キャンペーン期間中に決済画面でペイディの翌月あと払い、
        <OutboundLink
          href={`${PAIDY_HOST}/npay`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          3・6・12回あと払い
        </OutboundLink>
        、もしくは
        <OutboundLink
          href={`${PAIDY_HOST}/paidycard`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          ペイディカード
        </OutboundLink>
        を利用でお買い物すると、抽選で100名様に全額（最大10万円）キャッシュバックが当たる。
      </>
    ),
    note:
      '※キャンペーン期間中に上記条件でお買い物された方に、6月30日までにキャッシュバックを実施。6月30日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。',
  },
  {
    title: 'キャンペーン期間',
    text: '2023年5月15日 (月) 6:00～6月14日 (水) 23:59',
  },
];

export default function ConditionsV1({ conditions = DATA }) {
  return (
    <Box className={styles.container}>
      {conditions.map((data) => (
        <Box>
          <Typography className={styles.title}>{data.title}</Typography>
          <Typography className={styles.text}>{data.text}</Typography>
          {data.note && (
            <Typography className={styles.note}>{data.note}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
}
