import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import cx from 'classnames';
import styles from './ButtonOutboundLink.module.scss';

export default function ButtonOutboundLink(props) {
  const { className, isExternal, ...restProps } = props;

  return (
    <OutboundLink className={cx(styles.button, className)} {...restProps} />
  );
}
