/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const shareButtonsStyles = makeStyles(() => ({
  shareButtons: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  shareButton: {
    '&:not(:last-child)': {
      marginRight: 15,
    },
    '&>img': {
      height: 21,
    },
  },
}));
