// @flow
import React from 'react';
import cx from 'classnames';
import Paginate from 'react-paginate';

import styles from './PaginationColor.module.scss';

type Props = {
  page: number,
  gotoPage: Function,
  pageCount: number,
};

const PaginationColor = ({ page, gotoPage, pageCount }: Props) => {
  return (
    <div className={styles.paginationContainer}>
      <Paginate
        forcePage={page}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => {
          gotoPage(selected);
        }}
        containerClassName={styles.pagination}
        subContainerClassName={cx(styles.pagination, styles.page)}
        previousClassName={cx(styles.hidden)}
        nextClassName={cx(styles.hidden)}
        activeClassName={'active'}
      />
    </div>
  );
};

export default PaginationColor;
