import React from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import styles from './PinkBadge.module.scss';

export default function PinkBadge({
  children = 'NEW!',
  className,
  size = 'sm', // ["sm", "md"]
  ...restProps
}) {
  return (
    <Box
      className={classNames(styles.pinkBadge, className, {
        [styles.md]: size === 'md',
      })}
      {...restProps}
    >
      {children}
    </Box>
  );
}
