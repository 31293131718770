import React, { useEffect, useRef } from 'react';
import { BoxIndex } from '..';
import { NUMBER_OF_PICKS, STEP } from '../../constants';
import EditorPickItem from './EditorPickItem';
import styles from './EditorPicks.module.scss';

const EditorPicks = ({
  location = {},
  anchorId = 'editor-picks',
  items,
  className,
  style,
}) => {
  const [numberOfPicks, setNumberOfPicks] = React.useState(NUMBER_OF_PICKS);
  const isScrolled = useRef(false);

  const itemsWithAsset = items.filter((pick) => !!pick.imageURL);
  const filteredPicks = itemsWithAsset
    ? itemsWithAsset.slice(0, numberOfPicks)
    : [];

  const onShowMorePicks = (e) => {
    e.preventDefault();

    if (itemsWithAsset.length > numberOfPicks) {
      setNumberOfPicks(numberOfPicks + STEP);
    }
  };

  useEffect(() => {
    const { hash } = location;
    const isHashSameAsAnchorId = hash && hash.substring(1) === anchorId;

    if (
      !isScrolled.current &&
      isHashSameAsAnchorId &&
      filteredPicks.length > 0
    ) {
      setTimeout(() => document.getElementById(anchorId).scrollIntoView(), 500);
      isScrolled.current = true;
    }
  }, [filteredPicks.length]);

  if (!filteredPicks || filteredPicks.length <= 0) {
    return null;
  }

  return (
    <BoxIndex
      sectionStyle={{ maxWidth: '1128px' }}
      style={style}
      title="ピックアップ"
      anchorId={anchorId}
      linkText={items.length > numberOfPicks ? 'もっと見る' : ''}
      linkType="charcoal"
      linkAction={onShowMorePicks}
      wrapperClassName={className}
    >
      <div className={styles.articles}>
        {filteredPicks.map((pick, i) => (
          <>
            <div className={styles.pc}>
              <EditorPickItem key={i} pick={pick} isHeading={i === 0} />
            </div>
            <div className={styles.sp}>
              <EditorPickItem key={i} pick={pick} />
            </div>
          </>
        ))}
      </div>
    </BoxIndex>
  );
};

export default EditorPicks;
