import React, { useState, useCallback, useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import { Link } from 'gatsby';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
import styles from './StickyNav.module.scss';

const NAVS = ['キャンペーン概要', '６回あと払い', '対象ショップ'];

const useScrollAndSetActiveSection = (navs = NAVS) => {
  const [activeSection, setActiveSection] = useState(navs[0]);

  const onScroll = useCallback(() => {
    if (!document) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let i = 0; i < navs.length; i++) {
      const nav = navs[i];
      const rect = document.getElementById(nav)?.getBoundingClientRect();
      const isOnScreen = rect.top <= 64 && rect.bottom >= 64;

      if (isOnScreen) {
        setActiveSection(nav);
      }
    }
  }, [navs]);

  useEffect(() => {
    const throttledScroll = throttle(onScroll, 200);

    if (window) {
      window.addEventListener('scroll', throttledScroll, true);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', throttledScroll);
      }
    };
  }, [onScroll]);

  return activeSection;
};

export default function StickyNav({ navs = NAVS }) {
  const activeSection = useScrollAndSetActiveSection(navs);

  return (
    <section className={styles.section}>
      <nav
        className={classNames(styles.navs, {
          [styles.hasScroll]: navs.length > 2,
        })}
      >
        {navs.map((nav) => (
          <Box
            key={nav}
            className={classNames(styles.item, {
              [styles.active]: nav === activeSection,
            })}
          >
            <Link to={`#${nav}`}>{nav}</Link>
          </Box>
        ))}
      </nav>
      <Divider />
    </section>
  );
}
