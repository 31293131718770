/* eslint-disable import/no-dynamic-require, security/detect-non-literal-require */
// @flow
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import cx from 'classnames';
import { Box } from '@material-ui/core';
import BoxIndex from '../BoxIndex/BoxIndex';
import styles from './WhereToShop.module.scss';
import LinearCTAButton from '../LinearCTAButton';
import QRAndAppDownload from '../Shop/QRAndAppDownload';

const shops = [
  { name: 'amazon', url: 'https://www.amazon.co.jp/' },
  { name: 'SHEIN', url: 'https://jp.shein.com/' },
  {
    name: 'qoo10',
    url: 'https://www.qoo10.jp/',
    imgStyles: { maxHeight: '80%' },
  },
  {
    name: 'temu',
    url: 'https://www.temu.com/jp',
    src: require('../../images/shop-logos/temu.png'),
  },
  { name: 'dmm', url: 'https://www.dmm.com/' },
  { name: 'buyma', url: 'https://www.buyma.com/' },
  {
    name: 'agoda',
    url: 'https://www.agoda.com/ja-jp/',
    imgStyles: { maxHeight: '80%' },
  },
  { name: 'rakuma', url: 'https://fril.jp/' },
  { name: 'shoplist', url: 'https://shop-list.com/' },
  { name: 'biccamera', url: 'https://www.biccamera.com/' },
  { name: 'morecontact', url: 'https://morecon.jp/' },
  {
    name: 'willer',
    url: 'https://travel.willer.co.jp/',
    imgStyles: { maxHeight: '100%' },
  },
  {
    name: 'hoshino',
    url: 'https://www.hoshinoresorts.com/',
    src: require('../../images/shop-logos/hoshino.png'),
  },
  { name: 'hololive', url: 'https://shop.hololivepro.com/' },
  { name: 'Premium Bandai', url: 'https://p-bandai.jp/' },
  { name: '17 Live', url: 'https://jp.17.live/' },
  // not show
  // { name: 'gladd', url: 'https://gladd.jp/' },
  // { name: 'magaseek', url: 'https://www.magaseek.com/top/index/tp_1' },
  // { name: 'yamada', url: 'https://www.yamada-denkiweb.com/' },
  // { name: 'deanndeluca', url: 'https://www.deandeluca.co.jp/' },
];

const defaultDescription = (
  <>
    バーチャルカードを発行すると、Visaマークのあるネットショップで利用可能。さらに、リアルカードを発行すると、Visaマークのある街のお店でも利用可能に。
    <OutboundLink
      href="https://paidy.com/paidycard/"
      rel="noopener noreferrer"
      target="_blank"
    >
      ペイディカードの詳細はこちら
    </OutboundLink>
  </>
);

const WhereToShop = ({
  appUrlsSp,
  appUrlsPc,
  qrUrl,
  limit = shops.length,
  noSeeMore,
  style,
  className,
  title = 'いつものお店で、今すぐあと払い。',
  description = defaultDescription,
}) => (
  <BoxIndex
    anchorId="where_to_shop"
    title={title}
    mainClassName={cx(styles.whereToShop, className)}
    style={style}
    sectionStyle={{ maxWidth: 1124 }}
  >
    <nav className={styles.shops}>
      {shops.slice(0, limit).map(({ name, url, imgStyles, src }, i) => (
        <OutboundLink
          key={i}
          className=""
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            // $FlowFixMe
            src={!src ? require(`../../images/shop-logos/${name}.svg`) : src}
            alt={name}
            style={imgStyles}
          />
        </OutboundLink>
      ))}
    </nav>
    {!noSeeMore && (
      <>
        <p className={styles.description}>{description}</p>
        <LinearCTAButton text="使えるお店の詳細を見る" to="/shop" />
      </>
    )}
    <Box m="auto" mt={4}>
      <QRAndAppDownload
        appUrlsSp={appUrlsSp}
        appUrlsPc={appUrlsPc}
        qrUrl={qrUrl}
      />
    </Box>
  </BoxIndex>
);

export default WhereToShop;
