// @flow
import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Box.module.scss';

const Box = ({
  style,
  children,
  titleEn,
  titleJp,
  subTitle,
  mainClassName,
  contentClassName,
  linkType,
  linkText,
  linkTo,
  linkAction,
  isPop,
  type,
  anchorId,
  linkToExternal,
}: {
  style?: Object,
  children: React$Node,
  titleEn?: string,
  titleJp?: string,
  subTitle?: string,
  mainClassName?: string,
  contentClassName?: string,
  linkType?: string,
  linkText?: string,
  linkTo?: string,
  linkAction?: Function,
  isPop?: boolean,
  type?: string,
  anchorId: string,
  linkToExternal?: string,
}) => {
  const isFirst = titleEn && titleJp;

  return (
    <div
      id={anchorId}
      style={style}
      className={cx(
        styles.wrapper,
        isPop ? styles.pop : '',
        !isFirst ? styles.notFirst : ''
      )}
    >
      <section className={styles.section}>
        <main className={cx(styles.main, mainClassName)}>
          <div className={styles.sectionTitle}>
            {isFirst && (
              <>
                <small className={styles.titleEn}>{titleEn}</small>
                <i className={cx(styles.separator, styles[type])} />
                <h3 className={styles.titleJp}>{titleJp}</h3>
              </>
            )}
            {subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
          </div>
          <div className={cx(styles.content, contentClassName)}>
            {children}
            {!linkToExternal && linkText && (
              <Link
                className={`btn ${linkType || ''}`}
                to={linkTo}
                onClick={linkAction}
              >
                {linkText}
              </Link>
            )}
            {linkToExternal && (
              <OutboundLink
                className={`btn ${linkType || ''}`}
                href={linkToExternal}
                rel="noopener noreferrer"
                target="_blank"
              >
                {linkText}
              </OutboundLink>
            )}
          </div>
        </main>
      </section>
    </div>
  );
};

Box.defaultProps = {
  isFirst: true,
  titleEn: '',
  titleJp: '',
  subTitle: '',
  mainClassName: '',
  contentClassName: '',
  linkType: 'secondary',
  linkText: 'もっと見る',
  linkTo: '/shop/list/',
  linkAction: undefined,
  isPop: false,
  style: {},
  type: 'consumer',
  linkToExternal: undefined,
};

export default Box;
