// @flow
import React from 'react';
import cx from 'classnames';

import { BoxIndex } from '..';
import styles from './TopBanner.module.scss';
import heroBg from '../../images/hero-bg.png';
import heroBgSp from '../../images/hero-bg-sp.png';

const TopBanner = ({ bgImg, textAlign = 'left', className, children }) => (
  <>
    <div className={styles.pc}>
      <BoxIndex
        anchorId="top-banner"
        title=""
        wrapperClassName={cx(styles.heroWrapper, className)}
        style={{ backgroundImage: `url(${heroBg})` }}
      >
        {bgImg && (
          <img
            className={styles.foreground}
            alt="Top Banner Background"
            src={bgImg}
          />
        )}
        <h1 style={{ textAlign }}>{children}</h1>
      </BoxIndex>
    </div>
    <div className={styles.sp}>
      <BoxIndex
        anchorId="top-banner"
        title=""
        wrapperClassName={cx(styles.heroWrapper, className)}
        style={{ backgroundImage: `url(${heroBgSp})` }}
      >
        {bgImg && (
          <img
            className={styles.foreground}
            alt="Top Banner Background"
            src={bgImg}
          />
        )}
        <h1 style={{ textAlign }}>{children}</h1>
      </BoxIndex>
    </div>
  </>
);

export default TopBanner;
